/* Imports */
import axios from 'axios';
/* Helpers */
import { filterProductByGroup } from '../utils/mainUtils';

export default {
  state: {
    autocompleteProductList: [],
    autocompleteProductListError: false,
    products: [],
    stickyCards: [],
    pageProductIndex: {},
    metaData: {},
    currentPage: 1,
    displayGroup: true,
    swatches: [],
  },
  getters: {
    getAutocompleteProductList: (state) => state.autocompleteProductList,
    getAutocompleteProductListError: (state) => state.autocompleteProductListError,
    getProducts: (state) => {
      const returnResult = filterProductByGroup(state.products, state.displayGroup);

      // Recompute the total number of pages based on the number of products we display and update the meta data
      state.metaData.totalPages = Math.ceil(returnResult.length / state.metaData.itemsPerPage);
      // If we haven't computed the page indexes do it now
      const shouldUpdatePageProductIndex = Object.keys(state.pageProductIndex).length !== state.metaData.totalPages || !state.displayGroup;
      if (shouldUpdatePageProductIndex) {
        // Reset the page product index object when the pages change
        state.pageProductIndex = {};

        for (let i = 1; i <= state.metaData.totalPages; i += 1) {
          // We need to calculate each page to see exactly how many products fit
          // depending on how many sticky cards each page shows

          const currentPageStickyCards = state.stickyCards?.filter(
            // If displayOnPage does not exists we render the card on every page
            (card) => !card?.fields?.displayOnPages || card?.fields?.displayOnPages?.includes(i.toString()),
          ) || [];

          // Sticky cards that occupy the same placement index are discarded and only the first one is shown
          // eslint-disable-next-line max-len
          const visibleStickyCards = currentPageStickyCards.filter((card, index, self) => index === self.findIndex((el) => el?.fields?.placementIndex === card?.fields?.placementIndex));

          // This is the number of products that we will inject in the page so we always have 18 cards in total
          const productNumToBeShown = state.metaData.itemsPerPage - (visibleStickyCards.length ?? 0);

          // The start index will be 0 for first page. For next pages we will take the end value of the last page + 1
          const startIndex = i === 1 ? 0 : state.pageProductIndex[i - 1].endIndex + 1;

          // The end index will be either calculated from the last page plus the products that fit on the
          // current page or for the last page we simply set the end of the product list
          const endIndex = i === state.metaData.totalPages ? returnResult.length - 1 : startIndex + productNumToBeShown - 1;

          // Saving the computations in the state so we don't have to do it each time
          state.pageProductIndex[i] = {
            startIndex,
            endIndex,
          };
        }
      }

      const currentPageStartIndex = state.pageProductIndex[state.currentPage]?.startIndex ?? 0;
      const currentPageEndIndex = state.pageProductIndex[state.currentPage]?.endIndex ?? 0;
      return returnResult.slice(currentPageStartIndex, currentPageEndIndex + 1);
    },
    getStickyCards: (state) => state.stickyCards,
    getMetaData: (state) => state.metaData,
    getCurrentPage: (state) => state.currentPage,
    getDisplayGroup: (state) => state.displayGroup,
    getSwatches: (state) => state.swatches,
    getProductSwatches: (state) => (pid) => state.swatches.find((item) => item.productId === pid)?.swatches || [],
    getSeriesSwatches: (state) => (groupId) => state.swatches.filter((item) => item.groupId === groupId)
      .map((item) => item.swatches)
      .flat(),
  },
  mutations: {
    setAutocompleteProductList: (state, list) => {
      const formattedList = [];
      if (Array.isArray(list)) {
        list.forEach((item) => {
          let partNumber = false;

          if (item.partNumber) {
            partNumber = item.partNumber;
          }

          const shouldAddItemToList = item.productName && item.productId;

          if (shouldAddItemToList) {
            const product = {
              label: item.productName,
              value: {
                productId: item.productId,
                partNumber,
              },
            };

            formattedList.push(product);
          }
        });
      }

      state.autocompleteProductList = formattedList;
    },
    setProducts: (state, value) => {
      state.products = value;
    },
    setMetaData: (state, value) => {
      state.metaData = value;
    },
    setStickyCards: (state, value) => {
      state.stickyCards = value;
    },
    setDisplayGroup: (state, value) => {
      state.displayGroup = value;
    },
    setCurrentPage: (state, value) => {
      state.currentPage = value;
    },
    setAutocompleteProductListError: (state, value) => {
      state.autocompleteProductListError = value;
    },
    setSwatches: (state, value) => {
      state.swatches = value;
    },
  },
  actions: {
    loadProducts: async ({ commit, getters, dispatch }) => {
      const queryObject = {
        ...getters.getQueryParams,
        categoryKey: getters.getCategoryKey.trim(),
        locale: getters.getLocale,
        storeCode: getters.getStoreCode,
        appName: 'www-category-pages',
      };

      const queryString = new URLSearchParams(queryObject).toString();
      const url = `${window?.location?.origin}${getters.getPublicPath}/api/getProducts?${queryString}`;

      const response = await axios.get(url);

      if (response.status !== 200) {
        throw new Error(`Received ${response.status}: ${response.statusText}`);
      }

      if (response.length !== 0) {
        // Adjust meta data based on products shown
        const productsDisplayed = filterProductByGroup(response.data.products, getters.getDisplayGroup);
        const metaData = response.data.meta;
        metaData.totalPages = Math.ceil(productsDisplayed.length / metaData.itemsPerPage);

        if (response?.data?.meta?.currentPage > metaData.totalPages) {
          dispatch('setQueryParams', {
            ...getters.getQueryParams,
            currentPage: metaData.totalPages,
          });
        }
        const { products } = response.data;
        if (getters.getColorSwatchEnabled) {
          await dispatch('fetchColorSwatches', { products });
        }
        // set products (this will contain all products for all pages)
        commit('setProducts', products);

        // set meta object data
        commit('setMetaData', metaData);
        dispatch('fetchPrices');
      }
    },
    fetchColorSwatches: async ({ commit, dispatch }, payload) => {
      const { products } = payload;
      const productsOnly = products.filter((product) => !product.group);

      // Parsing only product-level data
      const swatchesPromises = productsOnly.map(async (product) => ({
        groupId: product.groupId,
        productId: product.id,
        swatches: await dispatch('loadColorSwatches', { id: product.id }),
      }));

      // Make a single array of all product swatches, breaking down groups
      const swatches = (await Promise.all(swatchesPromises));
      commit('setSwatches', swatches);
    },
    loadAutocompleteProductList: async ({ getters, commit }) => {
      // If we already have called and populated the autocompleteProductList getter, return early
      if ((getters.autocompleteProductList || []).length) return;

      try {
        const url = `${window?.location?.origin}${getters.getPublicPath}/api/getDisplayableProducts?locale=${getters.getLocale}`;
        const response = await axios.get(url);

        if (response.status !== 200) {
          throw new Error(`Received ${response.status}: ${response.statusText}`);
        }

        // set master product list (this will contain all products at Garmin)
        commit('setAutocompleteProductList', response?.data?.data || []);
      } catch (error) {
        commit('setAutocompleteProductListError', true);
      }
    },
    loadProductDetailsByPID: async ({ getters }, payload) => {
      const url = `${window?.location?.origin}${getters.getPublicPath}/api/getProductDetailsByPID`
        + `?pid=${payload}&locale=${getters.getLocale}`;
      const response = await axios.get(url);

      if (response.status !== 200) {
        throw new Error(`Received ${response.status}: ${response.statusText}`);
      }

      return response?.data?.data;
    },
    loadProductImageDetailsByPID: async ({ getters }, payload) => {
      const url = `${window?.location?.origin}${getters.getPublicPath}/api/getProductImageByPID`
        + `?pid=${payload}&locale=${getters.getLocale}`;
      const response = await axios.get(url);

      if (response.status !== 200) {
        throw new Error(`Received ${response.status}: ${response.statusText}`);
      }

      return response?.data?.data;
    },
    loadColorSwatches: async ({ getters }, payload) => {
      const body = {
        id: payload.id,
        locale: getters.getLocale,
      };
      const url = `${window?.location?.origin}${getters.getPublicPath}/api/getColorSwatches`;
      const response = await axios.post(url, body);

      if (response.status !== 200) {
        throw new Error(`Received ${response.status}: ${response.statusText}`);
      }

      return response?.data?.data;
    },
    // this should be false only if filters are applied
    loadDisplayGroup: ({ commit }, group) => {
      commit('setDisplayGroup', group);
    },
    loadStickyCards: ({ commit }, payload) => {
      commit('setStickyCards', payload);
    },
    loadCurrentPage: ({ getters, dispatch }, currentPageOpts) => {
      dispatch('setQueryParams', {
        ...getters.getQueryParams,
        ...currentPageOpts,
      });
    },
  },
};
